import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <section className="wrapper style5">
        <div className="inner">
          <header className="privacy-header">


            <h2>DAVID BEATTY</h2>
            <p className='about-subheader'>Founder + President of BEATTY DESIGN STUDIO</p>
          </header>

          <hr />

          <p className='about-paragraph'>
            I draw inspiration from the great mansions of Newport, Rhode Island, the human scale of Frank Lloyd Wright’s architecture, as well as the innovative design of my contemporaries. Having earned my Bachelor of Arts in Architectural Studies from the University of Illinois, I began my career at the international firm of Pei Cobb Freed & Partners in New York. There, I cultivated a keen attention to detail, which was further refined during my time at Charles T. Young Architects in New York. Over the course of a decade and a half at Landry Design Group, I honed my design skills while working across a wide array of architectural styles, materials, and locations.
          </p>

          <p className='about-paragraph'>
            Architecture should be functional without compromising on the emotions it evokes, whether it's introspection, curiosity, excitement, or tranquility. After conducting a comprehensive site analysis, we work with clients to find solutions that best accommodate their needs, while connecting with the site in a timeless expression of materiality and proportion. I find great satisfaction in guiding clients through the process, adeptly navigating the complexities of local codes, building sites, as well as the process of construction.
          </p>

          <hr />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
